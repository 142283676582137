export const environment = {
    production: false,
    adminServiceUrl: "https://stage.admin.farmrise.com/v1/admin",
    authProperty : {
        clientId: '679d8541-e127-4401-bea8-dfc3605aef8d',
        authority: 'https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78',
        redirectUri: 'https://stage.admin.farmrise.com/'
    }
};

